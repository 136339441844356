import React, { FC, useEffect, useMemo, useState } from 'react';
import { ContentCard, ContentCardProps } from './content-card';
import { ContentModalProps, GenericContentModal } from './generic-content-modal';
import { SectionHeader, SectionHeaderProps } from './section-header';
import { StyleSheet, View } from 'react-native';

export interface EditModalValue {
    [key: string]: string;
}

export interface GenericContentCardsContent {
    description: string;
    displayOrder?: string;
    displayReadMore: string;
    headingText: string;
    id?: string;
    link: string;
    type?: string;
}

export interface EditModalFields {
    contentFieldMapper: string;
    fieldName: string;
    label?: string;
    placeholder?: string;
    required: boolean;
    type?: 'TextInput';
}

export type GenericContentProps = {
    cardProps?: Partial<ContentCardProps>;
    contents?: GenericContentCardsContent[];
    deleteCallBack: (id: string) => void;
    headerProps?: Partial<SectionHeaderProps>;
    maxInstance?: number;
    minInstance?: number;
    modalProps?: Partial<ContentModalProps>;
    saveCallback: (value: EditModalValue) => void;
    sortingEnabled?: boolean;
};

const styles = StyleSheet.create({
    cardsContainer: {
        marginTop: 24,
    },
});

const initializeEditModalValue = (fields?: EditModalFields[]): EditModalValue =>
    fields?.reduce((acc, field) => ({ ...acc, [field.fieldName]: '' }), {}) || {};

export const GenericContentManager: FC<{ genericContentProps: GenericContentProps }> = ({ genericContentProps }) => {
    const {
        cardProps = {},
        contents = [],
        deleteCallBack,
        headerProps = {},
        maxInstance = 1,
        modalProps = {},
        saveCallback,
        sortingEnabled = false,
    } = genericContentProps;

    const editModalRefObject = useMemo(
        () => initializeEditModalValue(modalProps?.formFields),
        [modalProps?.formFields]
    );

    const [componentTypeId] = useState(() => Math.floor(Math.random() * 1000));
    const [draggingElementId, setDraggingElementId] = useState<number | null>(null);
    const [editModalEnteredValue, setEditModalEnteredValue] = useState<EditModalValue>(editModalRefObject);
    const [instanceSize, setInstanceSize] = useState<number>(contents.length);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [stateContent, setStateContent] = useState<GenericContentCardsContent[]>(contents);

    const editModalRequiredFields = useMemo(
        () => modalProps?.formFields?.filter((field) => field.required).map((field) => field.fieldName) || [],
        [modalProps?.formFields]
    );

    useEffect(() => {
        setStateContent(contents);
        setInstanceSize(contents.length);
    }, [contents]);

    const handleHideShowModal = () => setShowModal(false);

    const handleSave = () => {
        saveCallback(editModalEnteredValue);
        setShowModal(false);
        setEditModalEnteredValue(editModalRefObject);
    };

    const enableEditModalSubmitButton = useMemo(
        () => !editModalRequiredFields.every((field) => editModalEnteredValue[field]?.trim()),
        [editModalEnteredValue, editModalRequiredFields]
    );

    const handleDragStart = (event: React.DragEvent, index: number) => {
        if (sortingEnabled) {
            event.dataTransfer.setData('text/plain', componentTypeId.toString());
            setDraggingElementId(index);
        }
    };

    const handleDrop = (event: React.DragEvent, targetIndex: number) => {
        if (!sortingEnabled || draggingElementId === null) return;

        const sourceComponentTypeId = event.dataTransfer.getData('text/plain');
        if (sourceComponentTypeId === componentTypeId.toString()) {
            event.preventDefault();

            const updatedContent = [...stateContent];
            const [draggedItem] = updatedContent.splice(draggingElementId, 1);
            updatedContent.splice(targetIndex, 0, draggedItem);

            setStateContent(updatedContent);

            const mappedValue: EditModalValue = Object.keys(draggedItem).reduce((acc, key) => {
                acc[key] = String((draggedItem as any)[key] || '');
                return acc;
            }, {} as EditModalValue);

            saveCallback(mappedValue);
            setDraggingElementId(null);
        }
    };

    return (
        <>
            <SectionHeader
                title={headerProps.title || 'Messages'}
                description={`${instanceSize}/${maxInstance}`}
                onPress={() => {
                    setEditModalEnteredValue(editModalRefObject);
                    setShowModal(true);
                }}
                isDisabled={instanceSize >= maxInstance}
                buttonLabel={headerProps.buttonLabel || 'Add Message'}
            />
            <View>
                {stateContent.map((content, index) => (
                    <div
                        key={content.id || index}
                        onDrop={(event) => handleDrop(event, index)}
                        onDragStart={(event) => handleDragStart(event, index)}
                        onDragOver={(event) => event.preventDefault()}
                        draggable={sortingEnabled}
                    >
                        <div style={styles.cardsContainer}>
                            <ContentCard
                                content={content}
                                onDelete={deleteCallBack}
                                formFields={modalProps?.formFields || []}
                                toggleModalVisibility={handleHideShowModal}
                                hideCopyButton={!cardProps.hideCopyButton && instanceSize < maxInstance}
                                index={index}
                                onSave={saveCallback}
                                setFormData={setEditModalEnteredValue}
                                setShowModal={setShowModal}
                                contentList={stateContent}
                                title={`${index + 1} - ${cardProps.title}`}
                            />
                        </div>
                    </div>
                ))}
            </View>
            <GenericContentModal
                disabled={enableEditModalSubmitButton}
                formData={editModalEnteredValue}
                formFields={modalProps?.formFields || []}
                handleHideShowModal={handleHideShowModal}
                handleSubmit={handleSave}
                setFormData={setEditModalEnteredValue}
                showModal={showModal}
                submitButtonText={editModalEnteredValue.id ? 'Save' : modalProps.submitButtonText || 'Submit'}
                title={`${
                    editModalEnteredValue.id ? editModalEnteredValue.index : contents.length + 1
                } - ${modalProps.title || 'Edit Content'}`}
            />
        </>
    );
};
