import React, { FC } from 'react';
import { BmisSummaryCard } from '@web/bmis/components/bmis-summary-card';
import { Col, Row } from 'react-bootstrap';
import { DetailView, DetailViewBody, DetailViewContent } from '@web/components/detail-view';
import { QmrReports } from '../components/qmr-reports';
import { QuickReferences } from '../components/quick-references';
import { TechlineCases } from '../components/techline-cases';
import { isLocal } from '@web/utils/is-dev-environment';

export const VinSessionDashboard: FC = () => {
    return (
        <DetailView>
            <DetailViewContent topOffset="breadcrumb">
                <DetailViewBody>
                    <Row>
                        <Col xs={12} md={6} lg={4} className="mb-2">
                            <BmisSummaryCard />
                        </Col>
                    </Row>

                    {isLocal() && (
                        <>
                            <Row>
                                <Col className="mb-2">
                                    <QuickReferences />
                                </Col>
                            </Row>

                            <Row>
                                <Col className="mb-4">
                                    <TechlineCases />
                                </Col>
                            </Row>
                        </>
                    )}
                    <Row>
                        <Col className="mb-4">
                            <QmrReports />
                        </Col>
                    </Row>
                </DetailViewBody>
            </DetailViewContent>
        </DetailView>
    );
};
