import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useQuery from '@web/core/hooks/use-query';
import { useSummariesState } from '@web/core/hooks/use-summaries';
import {
    feedbackService,
    httpClient,
    investigationService,
    qmrsService,
    vinSessionService,
} from '@web/services/singletons';
import NavigationItem from '@web/components/navigation-item';
import NavigationSubItem from '@web/components/navigation-sub-item';
import colors from '@packages/core/styles/colors';
import { Button, Icon, Typography } from '@packages/ui/shared';
import { FeedbackGroup, FeedbackGroupType, InvestigationModel } from '@packages/models/api';
import { useAbortController } from '@packages/core/http';
import { useAuthState } from '@packages/contexts/auth';
import { NavigationItemHoverTitle } from './navigation-item-hover-title';
import { useAdvancedSearchContext } from '@packages/contexts/qmrs';
import { Collapse } from 'react-bootstrap';
import { useVinSessionState } from '@web/vin-sessions/context/vin-sessions.hooks';
import { VIN } from '@web/vin-sessions/types';
import { handleVinSessionError } from '@web/vin-sessions/utils/vinSessionUtils';
import { isDevEnvironment, isLocal } from '@web/utils/is-dev-environment';

type ExpandableNavItems = 'qmr' | 'feedback' | 'investigations' | 'administration' | 'techline';
const useNavigationStyles = createUseStyles({
    navigationOuter: ({ open }: { open: boolean }) => ({
        display: 'flex',
        position: 'relative',
        width: open ? 248 : 48,
        transition: '0.2s width',
    }),
    navigation: {
        display: 'flex',
        minWidth: '100%',
        padding: '16px 0 0 0',
        overflowY: 'auto',
        overflowX: 'hidden',
        flexDirection: 'column',
        backgroundColor: colors.grayTwo,
    },
    navigationSubItemCollapseButton: {
        border: 0,
        borderRadius: 0,
        display: 'block',
        padding: '10px 0',
        appearance: 'none',
        backgroundColor: 'transparent',
    },
    collapseButtonOuter: {
        position: 'absolute',
        bottom: 32,
        right: -12,
    },
    collapseButton: {
        width: 24,
        height: 24,
        display: 'flex',
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.blueFour,
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.16)',
    },
});

interface NavigationProps {
    open: boolean;
    onOpenTogglePress(open: boolean): void;
}
const Navigation: FC<NavigationProps> = ({ open, onOpenTogglePress }) => {
    const { t } = useTranslation();
    const landingRouteMatch = useMatch('/');
    const authRouteMatch = useMatch('/auth');
    const onboardingRouteMatch = useMatch('/onboard');
    const adminRouteMatch = useMatch('/administration');
    const adminWildcardRouteMatch = useMatch('/administration/*');
    const qmrRouteMatch = useMatch('/qmrs');
    const qmrWildcardRouteMatch = useMatch('/qmrs/*');
    const qmrDetailRouteMatch = useMatch('/qmrs/:id');
    const qmrEditRouteMatch = useMatch('/qmrs/:id/edit');
    const nqrDetailRouteMatch = useMatch('/nqrs/:id');
    const nqrEditRouteMatch = useMatch('/nqrs/:id/edit');
    const qmrsSlashDashboard = useMatch('/qmrs/dashboard/*');
    const nqQMRRouteMatch = useMatch('/nqrs');
    const advancedSearchResultsRouteMatch = useMatch('/qmrs/advanced-search/:searchId');
    const bmisDetailRouteMatch = useMatch('/vin/:vin/bmis/:rowGuid');
    const stisRouteMatch = useMatch('/vin/:vin/stis/*');
    const stisAltRouteMatch = useMatch('/stis/*');
    const savedSearchRouteMatch = useMatch('/qmrs/advanced-search/saved');
    const advancedSearchRouteMatch = useMatch('/qmrs/advanced-search/search');
    const techlineDashboardRouteMatch = useMatch('/techline/dashboard');
    const techlineAllCasesRouteMatch = useMatch('/techline/all-cases');
    const techlineCreateWorksheetRouteMatch = useMatch('/techline/worksheet-answer');
    const worksheetPreviewRouteMatch = useMatch('/administration/worksheet/preview/:worksheetId');
    const techlineNewOpenCasesRouteMatch = useMatch('/techline/:caseNumber');
    const isActualNewOpenCasesRouteMatch =
        techlineNewOpenCasesRouteMatch &&
        techlineNewOpenCasesRouteMatch?.params?.caseNumber !== 'dashboard' &&
        techlineNewOpenCasesRouteMatch?.params?.caseNumber !== 'all-cases';
    const techlineRecentSearchesRouteMatch = useMatch('/techline/recent-searches');
    const techlineOpenRouteMatch = useMatch('/techline/open');
    const techlineRouteMatch =
        !!techlineDashboardRouteMatch ||
        !!techlineAllCasesRouteMatch ||
        !!isActualNewOpenCasesRouteMatch ||
        !!techlineOpenRouteMatch ||
        !!techlineRecentSearchesRouteMatch;
    const investigationsRouteMatch = useMatch('/investigations');
    const investigationsWildcardRouteMatch = useMatch('/investigations/*');
    const escalationsRouteMatch = useMatch('/escalations');
    const escalationsWildcardRouteMatch = useMatch('/escalations/*');
    const investigationsDetailRouteMatch = useMatch('/investigations/:id');
    const feedbackRouteMatch = useMatch('/feedback');
    const feedbackWildcardRouteMatch = useMatch('/feedback/*');
    const manageDocumentationRouteMatch = useMatch('/user-articles/*');
    const vinSessionRouteMatch = useMatch('/vin/*');
    const bmisRouteMatch = useMatch('/vin/:id/bmis/*');
    const vinQmrRouteMatch = useMatch('/vin/:id/qmrs');
    const vinDashboardRouteMatch = useMatch('/vin/:id');

    const { account } = useAuthState();
    const [displayFlag, setDisplayFlag] = useState<boolean>(true);

    // Shared Contexts
    const { recentSavedSearches, setRecentSavedSearches } = useAdvancedSearchContext();
    const { selectedVin, isVinLoading } = useVinSessionState();

    // Vin Session Logic
    const selectedVinString = selectedVin?.vinRecord?.vin || '';
    const isWithinVinSession = selectedVinString && !isVinLoading;
    const canViewBmisInVinSession = account?.bmisCapabilities?.canViewBatteryTestReport && isWithinVinSession;

    const query = useQuery();
    const queryGroupType = query.get('groupType') || FeedbackGroupType.All;
    const queryEntityId = query.get('entityId');
    const { fetchQmrSummaries } = useSummariesState();
    const [savedSearchesOpen, setSavedSearchesOpen] = useState(true);
    const [permissions, setPermissions] = useState<any>([]);
    const [recentInvestigations, setRecentInvestigations] = useState<InvestigationModel[]>([]);
    const [publicationsCount, setPublicationsCount] = useState<number>(0);
    const hideNavigation = !!qmrsSlashDashboard
        ? false
        : authRouteMatch ||
          onboardingRouteMatch ||
          qmrDetailRouteMatch ||
          qmrEditRouteMatch ||
          nqrDetailRouteMatch ||
          nqrEditRouteMatch ||
          bmisDetailRouteMatch ||
          manageDocumentationRouteMatch ||
          !!techlineCreateWorksheetRouteMatch ||
          !!isActualNewOpenCasesRouteMatch ||
          worksheetPreviewRouteMatch;
    const classes = useNavigationStyles({ open });
    const { abortSignalRef } = useAbortController();
    const [feedbackGroups, setFeedbackGroups] = useState<FeedbackGroup[]>([]);
    const [hoveredMap, setHoveredMap] = useState<{ [key: string]: boolean }>({});
    const [currentHover, setCurrentHover] = useState('');
    const [currentRef, setCurrentRef] = useState<HTMLElement>();
    const [openNavItems, setOpenNavItems] = useState({
        qmr: false,
        feedback: false,
        investigations: false,
        administration: false,
        techline: false,
    });

    const { vinQmrsCount } = useVinSessionState();

    console.log(vinQmrsCount, 'vinQmrsCount');

    const showQmrsDashboard = useMemo(() => {
        const eligiblePermissions: string[] = ['DASHBOARD001'];
        const hasPermissions = permissions?.filter(
            (permission: any) => eligiblePermissions.indexOf(permission.permissionId) > -1
        );
        return hasPermissions && hasPermissions?.length > 0;
    }, [permissions]);

    // const showFailcodeTeamDashboard = useMemo(() => {
    //     return permissions?.some(
    //         (permission: any) => permission.permissionId === 'ADM003' || permission.permissionId === 'ADM012'
    //     );
    // }, [permissions]);

    const toggleNavItem = useCallback(
        (item: ExpandableNavItems, forceState?: boolean) => {
            setOpenNavItems({
                ...openNavItems,
                [item]: typeof forceState === 'boolean' ? forceState : !openNavItems[item],
            });
        },
        [openNavItems]
    );

    const fecthPublicationsCount = useCallback(
        async (vin: VIN | undefined) => {
            if (!vin) {
                return null;
            }

            try {
                const response = await vinSessionService.getPublicationsCount({
                    vin: vin.vinRecord.vin,
                    ignoreCache: true,
                });

                if (response.success) {
                    setPublicationsCount(response.data.docCount);
                }
            } catch (error) {
                handleVinSessionError('Open VIN Session Error', error);
                return;
            }
        },
        [selectedVin]
    );

    function handleNavigationTogglePress() {
        onOpenTogglePress(!open);
        setOpenNavItems({
            qmr: false,
            feedback: false,
            investigations: false,
            administration: false,
            techline: false,
        });
    }

    const fetchFeedbackSummary = useCallback(() => {
        return feedbackService
            .fetchFeedbackSummary({ signal: abortSignalRef.current })
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }
                setFeedbackGroups(response.data.summary.feedbackGroups);
            })
            .catch((e) => {
                alert(e.message);
            });
    }, [abortSignalRef]);

    const fetchRecentSearches = useCallback(async () => {
        try {
            const response = await qmrsService.getAdvancedSearchRecentSavedSearches();
            if (response.success) {
                setRecentSavedSearches(response.data.savedSearches);
            } else {
                throw response.data;
            }
        } catch (error) {
            window.alert(error.message);
        }
    }, [setRecentSavedSearches]);

    const expandCollapsedNavigation = useCallback(
        (item: ExpandableNavItems) => {
            onOpenTogglePress(true);
            toggleNavItem(item, true);
        },
        [onOpenTogglePress, toggleNavItem]
    );

    useEffect(() => {
        if (hideNavigation) {
            return;
        }
        fetchFeedbackSummary();
        fetchQmrSummaries();
        fetchRecentSearches();
    }, [abortSignalRef, fetchFeedbackSummary, fetchQmrSummaries, fetchRecentSearches, hideNavigation]);

    const shouldFetchInvestigations = account?.investigationCapabilities?.viewInvestigations;

    useEffect(() => {
        if (shouldFetchInvestigations && !hideNavigation && openNavItems.investigations) {
            investigationService
                .getInvestigations({
                    queryString: 'size=5',
                    signal: abortSignalRef.current,
                })
                .then((response) => {
                    if (!response.success && response.aborted) {
                        return;
                    } else if (!response.success) {
                        alert(response.data.message);
                        return;
                    }
                    setRecentInvestigations(response.data);
                });
        }
    }, [abortSignalRef, shouldFetchInvestigations, hideNavigation, openNavItems.investigations]);

    const fetchPermissions = useCallback(() => {
        if (!account || !account.accountId) {
            return;
        }
        const config = {
            method: 'GET' as const,
            signal: abortSignalRef.current,
            ignoreCache: true,
        };
        httpClient
            .orchestrateRequest<any>({
                ...config,
                url: `/accounts/${account.accountId}/permissions-debug`,
            })
            .then((permissionsResponse) => {
                if (permissionsResponse.success) {
                    let allPermissions = [...(permissionsResponse.data?.globalPermissions || [])];
                    if (permissionsResponse.data?.retailerPermissions?.length) {
                        permissionsResponse.data.retailerPermissions.forEach((item: any) => {
                            if (item.permissions?.length) {
                                allPermissions = [...allPermissions, ...item.permissions];
                            }
                        });
                    }
                    setPermissions(allPermissions);
                }
            })
            .catch((e) => {
                alert('Error: ' + e + '. Please refresh & try again.');
                console.log('error::', e);
            });
    }, [abortSignalRef, account]);

    useEffect(() => {
        fetchPermissions();
    }, [fetchPermissions]);

    useEffect(() => {
        setDisplayFlag(vinSessionRouteMatch ? false : true);
    }, [vinSessionRouteMatch]);

    useEffect(() => {
        fecthPublicationsCount(selectedVin);
    }, [selectedVin]);

    const handleOnHover = (title: string, ref?: any) => {
        hoveredMap[title] = true;
        setHoveredMap(hoveredMap);
        setCurrentHover(title);
        setCurrentRef(ref);
    };
    const handleOnHoverLeave = (title: string) => {
        hoveredMap[title] = false;
        setHoveredMap(hoveredMap);
        setCurrentHover('');
    };

    if (hideNavigation) {
        return null;
    }

    return (
        <>
            <div className={classes.navigationOuter}>
                <nav className={classes.navigation}>
                    <NavigationItem
                        iconName="home"
                        to={isWithinVinSession ? `/vin/${selectedVinString}` : '/'}
                        title={t('nav:dashboard')}
                        isActive={!!landingRouteMatch || !!vinDashboardRouteMatch}
                        onHover={handleOnHover}
                        onHoverLeave={handleOnHoverLeave}
                        displayFlag={true}
                    />

                    {/* STIS Publications*/}
                    <NavigationItem
                        stisIconFlag={true}
                        to={selectedVin ? `/vin/${selectedVinString}/stis` : `/stis`}
                        title={t('nav:stisPublications')}
                        isActive={!!stisRouteMatch || !!stisAltRouteMatch}
                        onHover={handleOnHover}
                        onHoverLeave={handleOnHoverLeave}
                        publicationsCount={publicationsCount}
                        displayFlag={isLocal() && displayFlag}
                    />

                    <NavigationItem
                        iconName="car-subaru"
                        to={showQmrsDashboard ? '/qmrs/dashboard' : '/qmrs'}
                        title={t('nav:qmrs.title')}
                        isActive={
                            showQmrsDashboard
                                ? !!qmrsSlashDashboard
                                : !!qmrRouteMatch ||
                                  !!qmrWildcardRouteMatch ||
                                  !!advancedSearchRouteMatch ||
                                  !!escalationsRouteMatch ||
                                  !!escalationsWildcardRouteMatch ||
                                  !!investigationsRouteMatch ||
                                  !!investigationsWildcardRouteMatch ||
                                  !!qmrsSlashDashboard
                        }
                        isMainNavActive={!!qmrsSlashDashboard}
                        open={openNavItems.qmr}
                        onHover={handleOnHover}
                        onHoverLeave={handleOnHoverLeave}
                        onCollapseToggle={() => toggleNavItem('qmr')}
                        onClick={() => expandCollapsedNavigation('qmr')}
                        displayFlag={displayFlag}
                    >
                        <NavigationSubItem
                            key="All QMRs"
                            badge=""
                            isActive={
                                (!!qmrRouteMatch || !!qmrWildcardRouteMatch) &&
                                !advancedSearchRouteMatch &&
                                !qmrsSlashDashboard
                            }
                            to="/qmrs"
                            title="Quality Monitoring Reports"
                        />
                        <NavigationSubItem
                            key="Non Quality Reports"
                            badge=""
                            isActive={!!nqQMRRouteMatch}
                            to="/nqrs"
                            title="Non Quality Reports"
                        />
                        {account?.systemCapabilities.canViewEscalations && (
                            <NavigationSubItem
                                to="/escalations"
                                title={t('nav:escalations', 'Escalations')}
                                isActive={!!escalationsRouteMatch || !!escalationsWildcardRouteMatch}
                            />
                        )}
                        {account?.investigationCapabilities?.viewInvestigations && (
                            <NavigationSubItem
                                to="/investigations"
                                title={t('nav:investigations')}
                                isActive={!!investigationsRouteMatch || !!investigationsWildcardRouteMatch}
                            >
                                <div className="pl-4 pr-4">
                                    <hr className="mb-2" />
                                    <Typography variant="caption2" color="graySix">
                                        Recent
                                    </Typography>
                                </div>
                                {recentInvestigations.length > 0 &&
                                    recentInvestigations.map((investigation, index) => (
                                        <NavigationSubItem
                                            key={index}
                                            title={investigation.title}
                                            to={'/investigations/' + investigation.investigationId}
                                            isActive={
                                                investigationsDetailRouteMatch
                                                    ? investigation.investigationId ===
                                                      investigationsDetailRouteMatch.params.id
                                                    : false
                                            }
                                        />
                                    ))}
                            </NavigationSubItem>
                        )}
                        <NavigationSubItem
                            to="/qmrs/advanced-search/search"
                            title="Advanced Search"
                            isActive={!!advancedSearchRouteMatch}
                        />
                        <div className="pl-4 pr-4">
                            <button
                                className={classes.navigationSubItemCollapseButton}
                                onClick={() => {
                                    setSavedSearchesOpen(!savedSearchesOpen);
                                }}
                            >
                                <Icon
                                    name={savedSearchesOpen ? 'arrow-small-up' : 'arrow-small-down'}
                                    size={16}
                                    style={{ marginRight: 16 }}
                                />
                                <Typography>Saved Searches</Typography>
                            </button>
                        </div>
                        <Collapse in={savedSearchesOpen}>
                            <div>
                                {recentSavedSearches.map((search) => {
                                    return (
                                        <NavigationSubItem
                                            key={search.savedSearchId}
                                            to={`/qmrs/advanced-search/${search.savedSearchId}`}
                                            title={search.name}
                                            iconName="paragraph"
                                            isSubOfSub
                                            isActive={
                                                advancedSearchResultsRouteMatch
                                                    ? search.savedSearchId ===
                                                      advancedSearchResultsRouteMatch.params.searchId
                                                    : false
                                            }
                                        />
                                    );
                                })}
                                <NavigationSubItem
                                    to="/qmrs/advanced-search/saved"
                                    title="View All"
                                    iconName="eye"
                                    isSubOfSub
                                    isActive={!!savedSearchRouteMatch}
                                />
                            </div>
                        </Collapse>
                    </NavigationItem>

                    <NavigationItem
                        iconName="car-subaru"
                        to={`/vin/${selectedVinString}/qmrs`}
                        title={t('nav:qmrReports')}
                        isActive={!!vinQmrRouteMatch}
                        onHover={handleOnHover}
                        onHoverLeave={handleOnHoverLeave}
                        displayFlag={vinSessionRouteMatch ? true : false}
                        publicationsCount={vinQmrsCount}
                    />

                    {/* BMIS */}
                    {canViewBmisInVinSession && (
                        <NavigationItem
                            iconName="battery"
                            to={`/vin/${selectedVinString}/bmis`}
                            title={t('nav:bmis')}
                            isActive={!!bmisRouteMatch}
                            onHover={handleOnHover}
                            onHoverLeave={handleOnHoverLeave}
                            displayFlag={vinSessionRouteMatch ? true : false}
                        />
                    )}

                    {account?.systemCapabilities?.canViewTechline && (
                        <NavigationItem
                            onHover={handleOnHover}
                            onHoverLeave={handleOnHoverLeave}
                            iconName="techline-logo"
                            to="/techline/dashboard"
                            title={t('nav:techline')}
                            isMainNavActive={!!techlineDashboardRouteMatch}
                            isActive={techlineRouteMatch}
                            open={openNavItems.techline}
                            onCollapseToggle={() => toggleNavItem('techline')}
                            onClick={() => expandCollapsedNavigation('techline')}
                            displayFlag={displayFlag}
                        />
                    )}

                    {/* Commented out per SD-955 for UAT */}
                    {account?.systemCapabilities.feedbackNavigationEnabled && (
                        <NavigationItem
                            iconName="chat-bubble-text"
                            to="/feedback"
                            title={t('nav:feedback')}
                            isActive={!!feedbackRouteMatch || !!feedbackWildcardRouteMatch}
                            open={openNavItems.feedback}
                            onCollapseToggle={() => toggleNavItem('feedback')}
                            onClick={() => expandCollapsedNavigation('feedback')}
                            onHover={handleOnHover}
                            onHoverLeave={handleOnHoverLeave}
                            displayFlag={displayFlag}
                        >
                            {feedbackGroups.map((feedbackGroup, idx) => {
                                let path = `/feedback?groupType=${feedbackGroup.feedbackGroupType}`;
                                if (feedbackGroup.entityId) {
                                    path += `&entityId=${feedbackGroup.entityId}`;
                                }
                                return (
                                    <NavigationSubItem
                                        key={idx}
                                        to={path}
                                        title={feedbackGroup.listDescription}
                                        badge={feedbackGroup.count > 0 ? feedbackGroup.countDescription : ''}
                                        isActive={
                                            feedbackGroup.entityId
                                                ? !!feedbackRouteMatch &&
                                                  feedbackGroup.feedbackGroupType === queryGroupType &&
                                                  (queryEntityId ? feedbackGroup.entityId === queryEntityId : false)
                                                : !!feedbackRouteMatch &&
                                                  feedbackGroup.feedbackGroupType === queryGroupType
                                        }
                                    />
                                );
                            })}
                        </NavigationItem>
                    )}
                    {account?.systemCapabilities.adminstrationNavigationEnabled && (
                        <div className="mt-auto">
                            <NavigationItem
                                iconName="administration"
                                title={t('Administration')}
                                to="/administration"
                                isActive={!!adminRouteMatch || !!adminWildcardRouteMatch}
                                onHover={handleOnHover}
                                onHoverLeave={handleOnHoverLeave}
                                isAdminHub={true}
                                displayFlag={displayFlag}
                            />
                        </div>
                    )}
                </nav>
                <div className={classes.collapseButtonOuter}>
                    <Button variant="link" onPress={handleNavigationTogglePress}>
                        <div className={classes.collapseButton}>
                            <Icon name={open ? 'chevron-left' : 'chevron-right'} color="blueOne" size={16} />
                        </div>
                    </Button>
                </div>
            </div>
            {currentHover && !open && <NavigationItemHoverTitle forwardRef={currentRef} title={currentHover} />}
        </>
    );
};
export default Navigation;
