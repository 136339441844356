import React, { useMemo } from 'react';
import { Button } from '@packages/ui/shared';
import { ContentSettingsLayout } from './components/content-settings-layout';
import {
    createOrUpdateHaveYouSeen,
    createOrUpdateQmrsDashboadMessage,
    createOrUpdateCounterMeasureOfTheMonth,
    deleteDashboardAlertLink,
    deleteHaveYouSeen,
    fetchAllHaveYouSeens,
    fetchQmrsDashboardAlertLink,
    fetchAllCounterMeasureOfTheMonth,
    deleteCounterMeasureOfMonth,
    fetchAllHelpfulLinks,
    createOrUpdateHelpfulLink,
    deleteHelpfulLink,
} from '@packages/contexts/qmrsdashboard';
import { DetailViewHeaderProps } from '@web/components/detail-view';
import { QmrsDashboardGenericContent } from './components/qmrs-dashboard/content-settings-qmrs-dashboard-genericcontent';
import { qmrsDashboardService } from '@web/services/singletons';
import { useNavigate } from 'react-router-dom';
import { useQmrsDashboardState, useQmrsDashboardDispatch } from '@packages/contexts/qmrsdashboard/qmrsdashboard.hooks';
import { useTranslation } from 'react-i18next';

export const ContentSettingsQmrsDashboardMain = () => {
    const { t } = useTranslation();
    const qmrsDashboardState = useQmrsDashboardState();
    const qmrsDashboardDispatch = useQmrsDashboardDispatch();
    const navigate = useNavigate();

    const { qmrsDashboardAlertLinks, haveYouSeens, counterMeasuresOfTheMonth, helpfullinks } = qmrsDashboardState;
    const qmrsDashboardAlertLinkContent =
        qmrsDashboardAlertLinks?.map((alertLink: any) => {
            return {
                id: alertLink.qmrDashAlertLinksId,
                headingText: alertLink.title,
                description: alertLink.description,
                link: alertLink.link,
                displayReadMore: 'true',
                displayOrder: alertLink.displayOrder,
            };
        }) || [];
    const haveYouSeenContent =
        haveYouSeens?.map((hys: any) => {
            return {
                headingText: hys.title,
                description: hys.description,
                link: hys.link,
                displayReadMore: 'true',
                id: hys.qmrDashHaveYouSeenId,
                displayOrder: hys.displayOrder,
            };
        }) || [];

    const counterMeasuresOfMonthContent =
        counterMeasuresOfTheMonth?.map((cmts: any) => {
            return {
                headingText: cmts.title,
                description: cmts.description,
                link: cmts.link,
                displayReadMore: 'true',
                id: cmts.qmrDashCounterMeasureId,
                displayOrder: cmts.displayOrder,
            };
        }) || [];

    const helpfulLinksContent = helpfullinks?.map((hpl: any) => {
        return {
            headingText: hpl.title,
            description: hpl.description,
            link: hpl.link,
            displayReadMore: 'false',
            id: hpl.qmrDashHelpfulLinksId,
            displayOrder: hpl.displayOrder,
        };
    });

    //fetch from backend service
    useMemo(() => {
        fetchQmrsDashboardAlertLink({
            id: undefined,
            qmrsDashboardDispatch,
            qmrsDashboardService,
        });
    }, [qmrsDashboardDispatch]);

    useMemo(() => {
        fetchAllHaveYouSeens({
            qmrsDashboardDispatch,
            qmrsDashboardService,
        });
    }, [qmrsDashboardDispatch]);

    useMemo(() => {
        fetchAllCounterMeasureOfTheMonth({
            qmrsDashboardDispatch,
            qmrsDashboardService,
        });
    }, [qmrsDashboardDispatch]);

    useMemo(() => {
        fetchAllHelpfulLinks({
            qmrsDashboardDispatch,
            qmrsDashboardService,
        });
    }, [qmrsDashboardDispatch]);

    //save button callbacks
    const saveBannerChanges = (createOrUpdateBanner: { [key: string]: string }) => {
        createOrUpdateQmrsDashboadMessage({
            qmrsDashboardAlertLink: {
                qmrDashAlertLinksId: createOrUpdateBanner.id,
                description: createOrUpdateBanner.description,
                link: createOrUpdateBanner.link,
                title: createOrUpdateBanner.title,
                displayOrder: createOrUpdateBanner.displayOrder,
            },
            qmrsDashboardDispatch,
            qmrsDashboardService,
        });
        return createOrUpdateBanner;
    };
    const saveHaveYouSeenValue = (haveYouSeen: { [key: string]: string }) => {
        createOrUpdateHaveYouSeen({
            haveYouSeen: {
                qmrDashHaveYouSeenId: haveYouSeen.id,
                title: haveYouSeen.header || haveYouSeen.headingText,
                link: haveYouSeen.link,
                description: haveYouSeen.description,
                displayOrder: haveYouSeen.displayOrder,
            },
            qmrsDashboardDispatch,
            qmrsDashboardService,
        });
        return haveYouSeen;
    };

    const saveCounterMeasureOfTheMonths = (counterMeasureOfMonths: { [key: string]: string }) => {
        createOrUpdateCounterMeasureOfTheMonth({
            counterMeasureOfTheMonth: {
                qmrDashCounterMeasureId: counterMeasureOfMonths.id,
                title: counterMeasureOfMonths.header || counterMeasureOfMonths.headingText,
                link: counterMeasureOfMonths.link,
                description: counterMeasureOfMonths.description,
                displayOrder: counterMeasureOfMonths.displayOrder,
            },
            qmrsDashboardDispatch,
            qmrsDashboardService,
        });
        return counterMeasureOfMonths;
    };

    const saveHelpfulLinks = (helpfullLink: { [key: string]: string }) => {
        createOrUpdateHelpfulLink({
            helpFulLink: {
                qmrDashHelpfulLinksId: helpfullLink.id,
                title: helpfullLink.header || helpfullLink.headingText,
                description: helpfullLink.description,
                link: helpfullLink.link,
                displayOrder: helpfullLink.displayOrder,
            },
            qmrsDashboardDispatch,
            qmrsDashboardService,
        });
        return helpfullLink;
    };

    const deleteAlertLinkCb = (id: string) => {
        deleteDashboardAlertLink({
            id,
            qmrsDashboardDispatch,
            qmrsDashboardService,
        });
        return id;
    };
    const deleteHaveYouSeenCb = (id: string) => {
        deleteHaveYouSeen({
            id,
            qmrsDashboardDispatch,
            qmrsDashboardService,
        });
        return id;
    };
    const deleteCounterMeasureOfMonthCb = (id: string) => {
        deleteCounterMeasureOfMonth({
            id,
            qmrsDashboardDispatch,
            qmrsDashboardService,
        });
        return id;
    };

    const deleteHelpFulLinkCb = (id: string) => {
        deleteHelpfulLink({
            id,
            qmrsDashboardDispatch,
            qmrsDashboardService,
        });
        return id;
    };

    const headerProps: DetailViewHeaderProps = useMemo(
        () => ({
            breadcrumbs: [
                { to: '/administration', title: t('administration:breadcrumbs.adminHub', 'Administration Hub') },
                {
                    to: '/administration/content-settings',
                    title: t('administration:breadcrumbs.contentSettings', 'Content Settings'),
                },
                {
                    to: '/administration/content-settings/qmrsdashboard',
                    title: t('administration:breadcrumbs.edit', 'Edit'),
                },
            ],
            subtitle: t('content-setting:qmrDashboard.subtitle', 'Quality Assurance Dashboard'),
            hasBack: true,
            rightSideItems: [
                <Button key="exit" variant="outlinePrimary" onPress={() => navigate(-1)}>
                    Exit
                </Button>,
            ],
        }),
        [t, navigate]
    );

    return (
        <ContentSettingsLayout
            headerProps={headerProps}
            message={t(
                'content-setting:qmrDashboard.displayMessage',
                'These messages will appear to DSQM users that visit the Quality Assurance Dashboard page.'
            )}
        >
            <div className="mb-7 row">
                <div className="col-md-8 offset-md-2">
                    <QmrsDashboardGenericContent
                        genericContentProps={{
                            translationkeyTitle: 'content-setting:qmrDashboard.bannerTitle',
                            defaultTitleValue: 'Banners',
                            editModalFields: [
                                {
                                    type: 'TextInput',
                                    fieldName: 'title',
                                    required: true,
                                    contentFieldMapper: 'headingText',
                                },
                                {
                                    type: 'TextArea',
                                    fieldName: 'description',
                                    contentFieldMapper: 'description',
                                    required: true,
                                },
                                {
                                    type: 'TextInput',
                                    fieldName: 'link',
                                    contentFieldMapper: 'link',
                                    required: false,
                                },
                            ],
                            contents: qmrsDashboardAlertLinkContent,
                            saveCallback: saveBannerChanges,
                            deleteCallBack: deleteAlertLinkCb,
                            maxInstance: 1,
                            addButtonText: 'Add Banner',
                            hideCopy: true,
                        }}
                    />
                </div>
            </div>
            <div className="mb-7 row">
                <div className="col-md-8 offset-md-2">
                    <QmrsDashboardGenericContent
                        genericContentProps={{
                            translationkeyTitle: 'administration:contentSettings.qmrsdashboard.haveYouSeenTitle',
                            defaultTitleValue: 'Have You Seen',
                            maxInstance: 10,
                            editModalFields: [
                                {
                                    type: 'TextInput',
                                    fieldName: 'header',
                                    required: true,
                                    contentFieldMapper: 'headingText',
                                },
                                {
                                    type: 'TextArea',
                                    fieldName: 'description',
                                    contentFieldMapper: 'description',
                                    required: true,
                                },
                                {
                                    type: 'TextInput',
                                    fieldName: 'link',
                                    contentFieldMapper: 'link',
                                    required: false,
                                },
                            ],
                            sortingEnabled: true,
                            contents: haveYouSeenContent,
                            deleteCallBack: deleteHaveYouSeenCb,
                            saveCallback: saveHaveYouSeenValue,
                        }}
                    />
                </div>
            </div>
            <div className="mb-7 row">
                <div className="col-md-8 offset-md-2">
                    <QmrsDashboardGenericContent
                        genericContentProps={{
                            translationkeyTitle: 'administration:contentSettings.qmrsdashboard.conterMeasureOfMonth',
                            defaultTitleValue: 'Improvements of the Month',
                            maxInstance: 10,
                            editModalFields: [
                                {
                                    type: 'TextInput',
                                    fieldName: 'header',
                                    required: true,
                                    contentFieldMapper: 'headingText',
                                },
                                {
                                    type: 'TextArea',
                                    fieldName: 'description',
                                    required: true,
                                    contentFieldMapper: 'description',
                                },
                                {
                                    type: 'TextInput',
                                    fieldName: 'link',
                                    required: false,
                                    contentFieldMapper: 'link',
                                },
                            ],
                            sortingEnabled: true,
                            contents: counterMeasuresOfMonthContent,
                            saveCallback: saveCounterMeasureOfTheMonths,
                            deleteCallBack: deleteCounterMeasureOfMonthCb,
                        }}
                    />
                </div>
            </div>
            <div className="mb-7 row">
                <div className="col-md-8 offset-md-2">
                    <QmrsDashboardGenericContent
                        genericContentProps={{
                            translationkeyTitle: 'administration:contentSettings.qmrsdashboard.helpfulLinks',
                            defaultTitleValue: 'Helpful Links',
                            addButtonText: 'Add Link',
                            cardHeaderText: 'Link',
                            maxInstance: 10,
                            editModalFields: [
                                {
                                    type: 'TextInput',
                                    fieldName: 'header',
                                    required: true,
                                    contentFieldMapper: 'headingText',
                                },
                                {
                                    type: 'TextArea',
                                    fieldName: 'description',
                                    required: true,
                                    contentFieldMapper: 'description',
                                },
                                {
                                    type: 'TextInput',
                                    fieldName: 'link',
                                    required: false,
                                    contentFieldMapper: 'link',
                                },
                            ],
                            contents: helpfulLinksContent,
                            saveCallback: saveHelpfulLinks,
                            deleteCallBack: deleteHelpFulLinkCb,
                        }}
                    />
                </div>
            </div>
        </ContentSettingsLayout>
    );
};
