import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, TouchableHighlight } from 'react-native';
import { Typography, Button, Icon, TextInputHelper, TextareaHelper } from '@packages/ui/shared';
import { default as Modal } from '@packages/ui/shared/modal';
import colors from '@packages/core/styles/colors';
import { createUseStyles } from 'react-jss';

export interface EditModalValue {
    [key: string]: string;
}

export interface GenericContentCardsContent {
    id?: string;
    headingText: string;
    description: string;
    link: string;
    displayReadMore: string;
    displayOrder?: string;
}

export interface EditModalFields {
    type?: 'TextInput' | 'TextArea';
    fieldName: string;
    required: boolean;
    contentFieldMapper: string;
}
export type QmrsDashboardGenericContentProps = {
    addButtonText?: string;
    cardHeaderText?: string;
    translationkeyTitle: string;
    defaultTitleValue: string;
    minInstance?: number;
    maxInstance?: number;
    editModalFields: EditModalFields[];
    contents?: GenericContentCardsContent[];
    saveCallback: (value: EditModalValue) => {};
    deleteCallBack: (id: string) => {};
    hideCopy?: boolean;
    sortingEnabled?: boolean;
};

const qmrsDashboardGenericContentMain = StyleSheet.create({
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    headerTextContentWrapper: {
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
    },
    btnContainer: {},
    cardsContainer: {
        marginTop: 10,
    },
    cardsHeader: {
        backgroundColor: colors.grayTwo,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10,
    },
    cardsHeaderLHSContent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 20,
    },
    cardsHeaderRHSContent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 20,
    },
    cardContentsBody: {
        backgroundColor: colors.white,
        borderLeftWidth: 2,
        borderRightWidth: 2,
        borderBottomWidth: 2,
        borderBottomColor: colors.grayTwo,
        borderLeftColor: colors.grayTwo,
        borderRightColor: colors.grayTwo,
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
});

const useGenericContentComponentClass = createUseStyles({
    genericCardsContentWithHeader: {
        '&:hover': {
            border: '1px solid #FDC133;',
        },
    },
    holdIconContainer: {
        '& div:nth-child(2)': {
            marginLeft: '-15px',
        },
    },
    skeleton: {},
});

const modalStyle = StyleSheet.create({
    main: {
        backgroundColor: colors.white,
    },
    header: {
        backgroundColor: colors.grayTwo,
        padding: 15,
        justifyContent: 'space-between',
    },
    row: {
        flexDirection: 'row',
    },
    spaceBetween: {
        justifyContent: 'space-between',
    },
    body: {
        padding: 15,
        minWidth: 400,
    },
    required: {
        color: colors.redOneActive,
    },
    txtWrapper: {
        marginBottom: 10,
    },
    bold: {
        fontWeight: 'bold',
    },
    hr: {
        borderColor: colors.grayTwo,
        boderWidth: StyleSheet.hairlineWidth,
    },
    footer: {
        flexDirection: 'row-reverse',
        paddingBottom: 10,
    },
    btnContainer: {
        marginRight: 5,
        width: 100,
    },
});

export const QmrsDashboardGenericContent: FC<{ genericContentProps: QmrsDashboardGenericContentProps }> = ({
    genericContentProps,
}) => {
    const { t } = useTranslation();
    const {
        maxInstance = 5,
        editModalFields,
        saveCallback,
        contents,
        addButtonText = 'Add Message',
        cardHeaderText = 'Message',
        deleteCallBack,
        hideCopy,
        sortingEnabled = false,
    } = genericContentProps;
    const [stateContent, setStateContent] = useState<GenericContentCardsContent[] | undefined>();
    const [componentTypeId] = useState(Math.floor(Math.random() * 1000));
    useEffect(() => {
        if (contents && contents.length > 0) {
            setStateContent([...contents]);
        }
    }, [contents]);
    const [instanceSize, setInstanceSize] = useState(0);
    const classes = useGenericContentComponentClass();
    const [showModal, setShowModal] = useState(false);
    const editModalRequiredFields = editModalFields.filter((v) => v.required === true).map((v) => v.fieldName);
    const editModalRefObject = editModalFields.map((v) => v.fieldName).reduce((a, v) => ({ ...a, [v]: '' }), {});
    const [editModalEnteredValue, setEditModalEnteredValue] = useState<EditModalValue>({ ...editModalRefObject });
    const [draggingElementId, setDraggingElementId] = useState<any>();
    useEffect(() => {
        setInstanceSize(contents?.length || 0);
    }, [contents]);
    const handleHideShowModal = () => {
        setShowModal(false);
    };
    const handleClickOnSave = () => {
        const result = saveCallback(editModalEnteredValue);
        if (result) {
            setShowModal(false);
            setEditModalEnteredValue({ ...editModalRefObject });
        }
    };
    const enableEditModalSubmitButton = useMemo(() => {
        const reqFeildTestArray = editModalRequiredFields.map(
            (reqFeild) => editModalEnteredValue[reqFeild] !== undefined && editModalEnteredValue[reqFeild] !== ''
        );

        const result = reqFeildTestArray.every((x) => x === true);
        return !result;
    }, [editModalEnteredValue, editModalRequiredFields]);
    return (
        <>
            <Modal visible={showModal} onHide={handleHideShowModal}>
                <View style={modalStyle.main}>
                    <View style={[modalStyle.header, modalStyle.row, modalStyle.spaceBetween]}>
                        <View>
                            <Typography style={modalStyle.bold} variant="lead">
                                {editModalEnteredValue.id
                                    ? t(
                                          `${genericContentProps.translationkeyTitle}.edit`,
                                          `Edit ${genericContentProps.defaultTitleValue}`
                                      )
                                    : t(
                                          `${genericContentProps.translationkeyTitle}.add`,
                                          `Add ${genericContentProps.defaultTitleValue}`
                                      )}
                            </Typography>
                        </View>
                        <TouchableHighlight onPress={handleHideShowModal}>
                            <Icon name="x-close" />
                        </TouchableHighlight>
                    </View>
                    <View style={modalStyle.body}>
                        {editModalFields.map((value, index) => {
                            if (value.type === 'TextInput') {
                                return (
                                    <View style={modalStyle.txtWrapper} key={value.fieldName + value + '' + index}>
                                        <TextInputHelper
                                            label={t(
                                                `administration:contentSettings.editmodal.label${value.fieldName}`,
                                                value.fieldName
                                            )}
                                            required={value.required}
                                            placeholder={t(
                                                `administration:contentSettings.editmodal.title${value.fieldName}`,
                                                `Enter ${value.fieldName}`
                                            )}
                                            value={editModalEnteredValue[value.fieldName]}
                                            onChangeText={(textValue: any) => {
                                                const presentValue = {
                                                    ...editModalEnteredValue,
                                                    [value.fieldName]: textValue,
                                                };
                                                setEditModalEnteredValue({ ...presentValue });
                                            }}
                                        />
                                    </View>
                                );
                            } else {
                                return (
                                    <View style={modalStyle.txtWrapper} key={value.fieldName + value + '' + index}>
                                        <TextareaHelper
                                            label={t(
                                                `administration:contentSettings.editmodal.label${value.fieldName}`,
                                                value.fieldName
                                            )}
                                            required={value.required}
                                            placeholder={t(
                                                `administration:contentSettings.editmodal.title${value.fieldName}`,
                                                `Enter ${value.fieldName}`
                                            )}
                                            value={editModalEnteredValue[value.fieldName]}
                                            onChangeText={(textValue: any) => {
                                                const presentValue = {
                                                    ...editModalEnteredValue,
                                                    [value.fieldName]: textValue,
                                                };
                                                setEditModalEnteredValue({ ...presentValue });
                                            }}
                                        />
                                    </View>
                                );
                            }
                        })}
                    </View>
                    <View style={modalStyle.footer}>
                        <View style={modalStyle.btnContainer}>
                            <Button
                                disabled={enableEditModalSubmitButton}
                                onPress={handleClickOnSave}
                                title={t(
                                    editModalEnteredValue.id
                                        ? 'administration:contentSettings.edit'
                                        : 'administration:contentSettings.save',
                                    editModalEnteredValue.id ? 'Save' : 'Save'
                                )}
                                variant={enableEditModalSubmitButton ? 'ghost-gray' : 'primary'}
                            />
                        </View>
                        <View style={modalStyle.btnContainer}>
                            <Button
                                title={t('administration.contentSettings.cancel', 'Cancel')}
                                variant="ghost-gray"
                                onPress={handleHideShowModal}
                            />
                        </View>
                    </View>
                </View>
            </Modal>
            <View style={qmrsDashboardGenericContentMain.headerContainer}>
                <View style={qmrsDashboardGenericContentMain.headerTextContentWrapper}>
                    <Typography variant="h4">
                        {t(genericContentProps.translationkeyTitle, genericContentProps.defaultTitleValue)}
                    </Typography>
                    <Typography variant="lead">
                        {contents?.length}/{maxInstance}
                    </Typography>
                </View>
                <View style={qmrsDashboardGenericContentMain.btnContainer}>
                    <Button
                        variant="tertiary"
                        onPress={() => {
                            setEditModalEnteredValue({ ...editModalRefObject });
                            setShowModal(true);
                        }}
                        disabled={instanceSize >= maxInstance}
                        title={t('administration:contentSettings.addMessage', addButtonText || `Add Message`)}
                    />
                </View>
            </View>
            <View>
                {stateContent
                    ?.sort((a, b) => {
                        if (a.displayOrder && b.displayOrder) {
                            return Number(a.displayOrder) - Number(b.displayOrder);
                        }
                        return 0;
                    })
                    .map((content, index) => (
                        <div
                            key={content.id}
                            onDrop={(event) => {
                                if (sortingEnabled) {
                                    const sourceComponentTypeId = event.dataTransfer.getData('text/plain');
                                    if (sourceComponentTypeId === String(componentTypeId)) {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        const displayOrder1 = stateContent[draggingElementId].displayOrder;
                                        stateContent[draggingElementId].displayOrder = stateContent[index].displayOrder;
                                        stateContent[index].displayOrder = displayOrder1;
                                        const result1: { [key: string]: string } = {};
                                        for (const [key, value] of Object.entries(stateContent[index])) {
                                            result1[key] = value;
                                        }
                                        const result2: { [key: string]: string } = {};
                                        for (const [key, value] of Object.entries(stateContent[draggingElementId])) {
                                            result2[key] = value;
                                        }
                                        //saveCallback(result1);
                                        saveCallback(result2);
                                        setDraggingElementId(-1);
                                    }
                                } else {
                                    return false;
                                }
                            }}
                            onDragStart={(event) => {
                                if (sortingEnabled) {
                                    event.dataTransfer.setData('text/plain', componentTypeId + '');
                                    setDraggingElementId(index);
                                } else {
                                    return false;
                                }
                            }}
                            draggable={sortingEnabled}
                            onDragOver={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                            }}
                            data-type={componentTypeId}
                            data-id={content.displayOrder}
                        >
                            <div
                                style={qmrsDashboardGenericContentMain.cardsContainer as any}
                                key={index}
                                className={`${classes.genericCardsContentWithHeader} ${classes.skeleton}`}
                            >
                                <View style={qmrsDashboardGenericContentMain.cardsHeader}>
                                    <View style={qmrsDashboardGenericContentMain.cardsHeaderLHSContent}>
                                        <TouchableHighlight onPress={handleHideShowModal}>
                                            <div className={classes.holdIconContainer}>
                                                <Icon name="more-dots-vertical" />
                                                <Icon name="more-dots-vertical" />
                                            </div>
                                        </TouchableHighlight>
                                        <Typography variant="lead">
                                            {index + 1} - {cardHeaderText}
                                        </Typography>
                                    </View>
                                    <View style={qmrsDashboardGenericContentMain.cardsHeaderRHSContent}>
                                        {hideCopy !== true && instanceSize < maxInstance && (
                                            <TouchableHighlight
                                                onPress={() => {
                                                    const tempValue: EditModalValue = { id: '', displayOrder: '' };
                                                    for (let [key, value] of Object.entries(content)) {
                                                        tempValue[key] = value;
                                                    }
                                                    const editValue = editModalFields.reduce(
                                                        (a, v) => ({
                                                            ...a,
                                                            [v.fieldName]: tempValue[v.contentFieldMapper],
                                                        }),
                                                        { id: '', displayOrder: stateContent.length + 1 + '' }
                                                    );
                                                    editValue['id'] = '';
                                                    saveCallback(editValue);
                                                }}
                                            >
                                                <Icon name="copy" color="blueOne" />
                                            </TouchableHighlight>
                                        )}
                                        <TouchableHighlight
                                            onPress={() => {
                                                const tempValue: EditModalValue = { id: '', displayOrder: '' };
                                                for (let [key, value] of Object.entries(content)) {
                                                    tempValue[key] = value;
                                                }
                                                const editValue = editModalFields.reduce(
                                                    (a, v) => ({
                                                        ...a,
                                                        [v.fieldName]: tempValue[v.contentFieldMapper],
                                                    }),
                                                    { id: '', displayOrder: '' }
                                                );
                                                editValue['id'] = content.id || '';
                                                editValue['displayOrder'] = content.displayOrder || '';
                                                setEditModalEnteredValue(editValue);
                                                setShowModal(true);
                                            }}
                                        >
                                            <Icon name="pencil" color="blueOne" />
                                        </TouchableHighlight>
                                        <TouchableHighlight
                                            onPress={() => {
                                                const tempValue: EditModalValue = { id: '' };
                                                for (let [key, value] of Object.entries(content)) {
                                                    tempValue[key] = value;
                                                }
                                                const editValue = editModalFields.reduce(
                                                    (a, v) => ({
                                                        ...a,
                                                        [v.fieldName]: tempValue[v.contentFieldMapper],
                                                    }),
                                                    { id: '' }
                                                );
                                                editValue['id'] = content.id || '';
                                                if (deleteCallBack) {
                                                    deleteCallBack(editValue.id);
                                                }
                                            }}
                                        >
                                            <Icon name="trash" color="redOne" />
                                        </TouchableHighlight>
                                    </View>
                                </View>
                                <View style={qmrsDashboardGenericContentMain.cardContentsBody}>
                                    <Typography variant="h6">{content.headingText}</Typography>
                                    {content.description && (
                                        <Typography variant="default">{content.description}</Typography>
                                    )}
                                    <View>
                                        {content.displayReadMore === 'true' && (
                                            <Typography variant="caption2">Read More</Typography>
                                        )}
                                        <Typography variant="default">{content.link}</Typography>
                                    </View>
                                </View>
                            </div>
                        </div>
                    ))}
            </View>
        </>
    );
};
