import React, { useEffect, useMemo } from 'react';
import { Button } from '@packages/ui/shared';
import { createUseStyles } from 'react-jss';
import { dashboardService } from '@web/services/singletons';
import {
    EditModalValue,
    GenericContentCardsContent,
    GenericContentManager,
} from './components/techshare/generic-content';
import { isEmpty } from 'lodash';
import { StyleSheet } from 'react-native';
import { useAbortController } from '@packages/core/http';
import { useDashboardState, useDashboardDispatch } from '@packages/contexts/dashboard/dashboard.hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    fetchDashboard,
    fetchDashboardUpdates,
    createOrUpdateDashboard,
    addOrUpdateDashboardUpdate,
    deleteDashboardUpdate,
} from '@packages/contexts/dashboard/dashboard.context';
import colors from '@packages/core/styles/colors';
import { BodyContainer, ContentSettingsLayout } from './components/content-settings-layout';

const styles = StyleSheet.create({
    contentMessage: {
        fontSize: 15,
        fontWeight: '400',
        lineHeight: 24,
    },
});

const useStyles = createUseStyles({
    divider: {
        backgroundColor: colors.grayThree,
        border: 0,
        height: '1px',
        margin: 0,
        width: '100%',
        marginBottom: '24px',
        marginTop: '24px',
    },
});

export const ContentSettingsTechshare: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dashboardState = useDashboardState();
    const dashboardDispatch = useDashboardDispatch();
    const { abortSignalRef } = useAbortController();
    const navigate = useNavigate();

    const { dashboard, dashboardUpdates } = dashboardState;

    const dashboardContent: GenericContentCardsContent[] = !isEmpty(dashboard?.dashboardId)
        ? [
              {
                  description: dashboard.bannerMessage,
                  displayOrder: dashboard.displayOrder,
                  displayReadMore: 'true',
                  headingText: dashboard.bannerHeader,
                  id: dashboard.dashboardId,
                  link: dashboard.bannerLink,
              },
          ]
        : [];

    const dashboardUpdatesContent: GenericContentCardsContent[] = dashboardUpdates?.map((update) => ({
        description: update.description,
        displayOrder: update.displayOrder,
        displayReadMore: 'true',
        headingText: update.heading,
        id: update.dashboardUpdateId,
        link: update.link,
        type: update.type,
    }));

    useEffect(() => {
        fetchDashboard({
            dashboardDispatch,
            dashboardService,
            signal: abortSignalRef.current,
        });
        fetchDashboardUpdates({
            dashboardDispatch,
            dashboardService,
            signal: abortSignalRef.current,
        });
    }, [abortSignalRef, dashboardDispatch]);

    const saveBanner = (banner: EditModalValue) => {
        createOrUpdateDashboard({
            dashboardDispatch,
            dashboardService,
            dashboardId: dashboard.dashboardId,
            newDashboard: {
                dashboardId: banner.id,
                bannerMessage: banner.description,
                bannerLink: banner.link,
                bannerHeader: banner.title,
            },
        });
        return banner;
    };

    const deleteBanner = (id: string) => {
        createOrUpdateDashboard({
            dashboardDispatch,
            dashboardService,
            dashboardId: dashboard.dashboardId,
            newDashboard: {
                ...dashboard,
                dashboardId: id,
                deleted: true,
            },
        });
        return id;
    };

    const saveDashboardUpdates = (update: EditModalValue) => {
        addOrUpdateDashboardUpdate({
            dashboardDispatch,
            dashboardService,
            dashboardUpdate: {
                dashboardUpdateId: update.id,
                heading: update.title || update.headingText,
                description: update.description,
                link: update.link,
                type: update.type,
                displayOrder: update.displayOrder,
            },
        });
        return update;
    };

    const deleteDashboardUpdates = (id: string) => {
        deleteDashboardUpdate({
            dashboardDispatch,
            updateDashboardId: id,
            dashboardService,
        });
        return id;
    };

    const headerProps = useMemo(
        () => ({
            breadcrumbs: [
                { to: '/administration', title: t('administration:breadcrumbs.adminHub', 'Administration Hub') },
                { to: '/administration/content-settings', title: 'Content Settings' },
                { to: '/administration/content-settings/techshare', title: 'Edit' },
            ],
            subtitle: 'Techshare Dashboard',
            rightSideItems: [
                <Button key="exit" variant="outlinePrimary" onPress={() => navigate(-1)}>
                    Exit
                </Button>,
            ],
            hasBack: true,
        }),
        [t, navigate]
    );

    return (
        <ContentSettingsLayout
            headerProps={headerProps}
            message="This message will appear to all users that visit the techshare page."
        >
            <BodyContainer divider={true}>
                <GenericContentManager
                    genericContentProps={{
                        headerProps: {
                            title: 'Banners',
                            buttonLabel: 'Add Banner',
                        },
                        cardProps: {
                            title: 'Banner',
                            hideCopyButton: true,
                        },
                        modalProps: {
                            title: 'Banner',
                            submitButtonText: dashboardContent.length > 0 ? 'Edit Banner' : 'Add Banner',
                            formFields: [
                                {
                                    type: 'TextInput',
                                    fieldName: 'title',
                                    required: true,
                                    contentFieldMapper: 'headingText',
                                    placeholder: 'Write title here',
                                    label: 'Title',
                                },
                                {
                                    type: 'TextInput',
                                    fieldName: 'description',
                                    contentFieldMapper: 'description',
                                    required: true,
                                    placeholder: 'Write copy here',
                                    label: 'Description',
                                },
                                {
                                    type: 'TextInput',
                                    fieldName: 'link',
                                    contentFieldMapper: 'link',
                                    placeholder: 'Write Edit here',
                                    label: 'Link',
                                    required: false,
                                },
                            ],
                        },
                        contents: dashboardContent,
                        saveCallback: saveBanner,
                        deleteCallBack: deleteBanner,
                        maxInstance: 1,
                        sortingEnabled: false,
                    }}
                />
            </BodyContainer>

            <BodyContainer>
                <GenericContentManager
                    genericContentProps={{
                        headerProps: { title: 'Updates', buttonLabel: 'Add Update' },
                        cardProps: { title: 'Update', hideCopyButton: false },
                        modalProps: {
                            title: 'Update',
                            submitButtonText: 'Add Update',
                            formFields: [
                                {
                                    type: 'TextInput',
                                    fieldName: 'type',
                                    required: true,
                                    contentFieldMapper: 'type',
                                    placeholder: 'Write type here',
                                    label: 'Type',
                                },
                                {
                                    type: 'TextInput',
                                    fieldName: 'title',
                                    required: true,
                                    contentFieldMapper: 'headingText',
                                    placeholder: 'Write title here',
                                    label: 'Title',
                                },
                                {
                                    type: 'TextInput',
                                    fieldName: 'description',
                                    contentFieldMapper: 'description',
                                    required: true,
                                    placeholder: 'Write copy here',
                                    label: 'Description',
                                },
                                {
                                    type: 'TextInput',
                                    fieldName: 'link',
                                    contentFieldMapper: 'link',
                                    required: false,
                                    placeholder: 'Write Edit here',
                                    label: 'Link',
                                },
                            ],
                        },
                        contents: dashboardUpdatesContent,
                        saveCallback: saveDashboardUpdates,
                        deleteCallBack: deleteDashboardUpdates,
                        maxInstance: 3,
                        sortingEnabled: false,
                    }}
                />
            </BodyContainer>
        </ContentSettingsLayout>
    );
};
